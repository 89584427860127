<template>
  <div id="app" ref="outerDom">
    <template v-if="$route.meta.separatePage">
      <router-view />
    </template>
    <template v-else>
      <header class="app_header">
        <div class="top">
          <div class="page_center flex_center_space-between">
            <!-- 欢迎您来到深圳文化产权交易所，中国文化产业专属的要素交易市场！ -->
            <div class="left">国家网信办区块链信息服务备案</div>

            <ul class="list">
              <li>
                <el-dropdown @command="handleCommand" v-if="isLogin">
                  <li>
                    <div class="user">
                      <router-link
                        style="margin-right: 8px"
                        to="/auctionInfo/myAuction"
                      >
                        我的拍卖
                      </router-link>
                      <router-link to="/auctionInfo/myOrders">
                        我的订单
                      </router-link>
                      <img src="@/assets/images/tx.png" />
                      <svg-icon icon-class="jt_icon" />
                    </div>

                    <el-dropdown-menu slot="dropdown" class="user_info_menu">
                      <div class="user_info">
                        <img src="@/assets/images/tx.png" />
                        <span>{{ userInfo.userEntity.telNum2 }}</span>
                      </div>
                      <!-- <el-dropdown-item command="updatePassword"
                      >修改密码</el-dropdown-item
                    > -->
                      <el-dropdown-item command="loginOut"
                        >退出登录</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </li>
                </el-dropdown>
                <span v-else @click="$store.commit('loginShowSwitch')"
                  >登录</span
                >
                <!-- /注册 -->
              </li>
              <!-- <li><span>微信公众平台</span></li> -->
              <li>
                <span><router-link to="contactUs">联系我们</router-link></span>
              </li>
              <li>
                <a
                  href="https://www.szcaee.cn/page/361/kind/13561.html"
                  target="_blank"
                  >关于我们</a
                >
              </li>
              <li>
                <a
                  href="https://www.szcaee.cn/home/pviews/render/content/complaint.html"
                  target="_blank"
                  >投诉建议</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="middle page_center flex_center_space-between">
          <div
            class="logo"
            @click="$route.name != 'homePage' ? $router.push('/homePage') : ''"
          >
            <img src="@/assets/images/logo.png" alt="" />
            <div class="text">
              <!-- <h1>深圳文化产权交易所</h1> -->
              <h1>深圳文化产权交易所</h1>
              <!-- <b>BETA</b> -->
              <h4>数字认证交易运营平台</h4>
            </div>
          </div>
          <!-- <div class="nav"> -->
          <el-dropdown
            placement="bottom"
            v-for="(list, index) in navList"
            :key="index"
            @command="navSkip"
            :split-button="list.sub ? false : true"
          >
            <router-link :to="list.path">{{ list.text }}</router-link>
            <el-dropdown-menu slot="dropdown" class="nav_menu">
              <el-dropdown-item
                v-for="(item, i) in list.sub"
                :key="i"
                :command="item"
                >{{ item.text }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- </div> -->
          <div class="search">
            <el-input
              placeholder="请输入搜索内容"
              v-model="searchVal"
              class="input-with-select"
              @keyup.enter.native="toSearch"
            >
              <el-select v-model="select" slot="prepend" placeholder="请选择">
                <el-option label="艺术藏品" value="1"></el-option>
                <el-option label="艺术家" value="2"></el-option>
                <el-option label="艺术机构" value="3"></el-option>
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="toSearch"
              ></el-button>
            </el-input>
          </div>
        </div>
        <!-- <div class="bottom page_center">
        <div class="sort">
          <el-dropdown @command="toSort">
            <p>全部分类</p>
            <el-dropdown-menu slot="dropdown" class="sort_menu">
              <el-dropdown-item
                v-for="item in sort"
                :key="item.categoryId"
                :command="item"
                >{{ item.categoryName }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="nav">
          <el-dropdown
            placement="bottom"
            v-for="(list, index) in navList"
            :key="index"
            @command="navSkip"
            :split-button="list.sub ? false : true"
          >
            <router-link :to="list.path">{{ list.text }}</router-link>
            <el-dropdown-menu slot="dropdown" class="nav_menu">
              <el-dropdown-item
                v-for="(item, i) in list.sub"
                :key="i"
                :command="item"
                >{{ item.text }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div v-if="!isLogin" class="nav_login">
            <span
              style="cursor: pointer"
              @click="$store.commit('loginShowSwitch')"
              >登录/注册</span
            >
          </div>
        </div>
      </div> -->
      </header>
      <router-view />
      <footer class="app_footer">
        <div class="page_center">
          <!-- <p>关于我们 | 法律声明 投资洽谈 | 联系我们 工商执照 | 舞弊举报</p>
        <p>
          版权所有2023-现在 深圳文化产权交易所 粤ICP备18094129号
          增值电信业务经营许可证：粤B2-20191323
        </p> -->
          <div class="top">
            <div class="swc">
              <p>深圳文化产权交易所<br />数字认证交易运营平台</p>
            </div>
            <!-- <div class="ewm">
              <img src="@/assets/images/wxkf.png" width="75" height="75" />
              <p>关注我们</p>
            </div>
            <div class="ewm">
              <img src="@/assets/images/download.png" width="75" height="75" />
              <p>用户APP</p>
            </div>
            <div class="ewm">
              <img src="@/assets/images/download.tool.png" width="75" height="75" />
              <p>运营APP</p>
            </div> -->
            <ul>
              <li>
                <dl>
                  <dt>关于平台</dt>
                  <dd>平台简介</dd>
                  <dd>企业文化</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>发展荣誉</dt>
                  <dd>主营业务</dd>
                  <dd>发展历程</dd>
                  <dd>荣誉证书</dd>
                  <dd>领导关怀</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>法律资讯</dt>
                  <dd>反洗钱措施</dd>
                  <dd>服务协议</dd>
                  <dd>隐私政策</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>知识产权</dt>
                  <dd>知识产权</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>
                    <a
                      href="https://www.szcaee.cn/page/361/kind/13561.html"
                      target="_blank"
                      >关于我们</a
                    >
                  </dt>
                  <dd>关注我们</dd>
                  <dd><router-link to="contactUs">联系我们</router-link></dd>
                </dl>
              </li>
            </ul>
          </div>
          <div class="bottom">
            <p>
              版权所有2023-现在 深圳文化产权交易所 粤ICP备18094129
              增值电信业务经营许可证：粤B2-20191323
            </p>
          </div>
        </div>
      </footer>

      <img src="@//assets/images/login.png" alt="" style="display: none" />
      <transition name="el-fade-in-linear">
        <div class="login_box" v-show="loginShow" v-if="!isLogin">
          <div class="login">
            <div class="left">
              <img src="../public/logo.png" />
              <h2>深圳文化产权交易所</h2>
              <!-- <h3>Genuine cultural works of art certification platform</h3> -->
              <h3>Shenzhen Cultural Property Exchange</h3>
            </div>
            <div class="right">
              <div v-show="!passwordSuccess">
                <i
                  class="el-icon-close"
                  @click="$store.commit('loginShowSwitch', $refs.ruleForm)"
                ></i>
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  class="ruleForm"
                >
                  <h2 v-show="registerLoginShow && !retrievePassword">注册</h2>
                  <h2 v-show="!registerLoginShow && !retrievePassword">
                    <span
                      :style="
                        mobileLogin
                          ? 'cursor: pointer;'
                          : 'color:var(--subjectColor);'
                      "
                      style="margin-right: 30px"
                      @click="mobileLogin = false"
                      >账号登录</span
                    >
                    <span
                      :style="
                        !mobileLogin
                          ? 'cursor: pointer;'
                          : 'color:var(--subjectColor);'
                      "
                      @click="mobileLogin = true"
                      >手机号登录</span
                    >
                  </h2>
                  <h2
                    style="text-align: center; color: var(--subjectColor)"
                    v-show="retrievePassword"
                  >
                    找回密码
                  </h2>
                  <el-form-item prop="user">
                    <el-input
                      type="text"
                      v-model="ruleForm.user"
                      autocomplete="off"
                      :placeholder="
                        mobileLogin ? '请输入手机号' : '请输入用户名'
                      "
                      :maxlength="11"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    prop="authCode"
                    v-show="
                      registerLoginShow || mobileLogin || retrievePassword
                    "
                  >
                    <el-input
                      placeholder="请输入验证码"
                      v-model="ruleForm.authCode"
                      :maxlength="6"
                      :minlength="6"
                    >
                      <template slot="append">
                        <div
                          @click="captchaMethod"
                          class="code"
                          :style="countdown ? 'cursor: default' : ''"
                        >
                          {{ countdown ? countdown : "发送验证码" }}
                        </div>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    prop="pass"
                    v-show="registerLoginShow || !mobileLogin"
                  >
                    <el-input
                      type="password"
                      v-model="ruleForm.pass"
                      autocomplete="off"
                      placeholder="请输入密码"
                      :minlength="6"
                    ></el-input>
                  </el-form-item>

                  <!-- <el-form-item v-if="!registerLoginShow">
                  <el-button type="primary" @click="submitForm('ruleForm')"
                    >登录</el-button
                  >
                </el-form-item> -->
                  <div
                    class="clause"
                    v-if="registerLoginShow"
                    @click="clause = !clause"
                  >
                    <input type="checkbox" v-model="clause" />
                    <div>
                      我已阅读并接受以下条款：<a
                        href="https://zph.origmall.cn/Agreement/TermsOfService.pdf"
                        target="_blank"
                        >《服务条款》</a
                      ><a
                        href="https://zph.origmall.cn/Agreement/PrivacyPolicy.pdf"
                        target="_blank"
                        >《隐私政策》</a
                      ><a
                        href="https://zph.origmall.cn/Agreement/UserServiceAgreement.pdf"
                        target="_blank"
                        >《用户服务协议》</a
                      >
                    </div>
                  </div>
                  <el-button
                    class="register"
                    type="primary"
                    v-show="registerLoginShow"
                    @click="
                      ruleForm.user && ruleForm.pass && ruleForm.authCode
                        ? register('ruleForm')
                        : ''
                    "
                    :style="
                      ruleForm.user && ruleForm.pass && ruleForm.authCode
                        ? ''
                        : 'background:#A6A6A6;border-color:#A6A6A6'
                    "
                    >注册</el-button
                  >
                </el-form>
                <el-button
                  style="
                    border-color: var(--subjectColor);
                    background: var(--subjectColor);
                    font-size: 16px;
                  "
                  type="primary"
                  :loading="btnLoading"
                  @click="submitForm('ruleForm')"
                  >{{ retrievePassword ? "修改密码" : "登录" }}</el-button
                >
                <p
                  class="forgot"
                  v-show="!mobileLogin"
                  @click="retrievePassword = !retrievePassword"
                >
                  {{ retrievePassword ? "去登录" : "忘记密码" }}
                </p>
                <!-- <div class="toggle">
                <p v-show="!registerLoginShow">还没有账号？</p>
                <el-button
                  type="primary"
                  v-show="!registerLoginShow"
                  @click="registerLoginShow = true"
                  >立即注册</el-button
                >

                <p v-show="registerLoginShow">
                  已有账号？<a @click="registerLoginShow = false">立即登录</a>
                </p>
              </div> -->
              </div>
              <div v-if="passwordSuccess" style="text-align: center">
                <i
                  class="el-icon-close"
                  @click="$store.commit('loginShowSwitch', $refs.ruleForm)"
                ></i>
                <h2 style="color: var(--subjectColor)">找回密码</h2>
                <p
                  style="
                    display: flex;
                    font-size: 14px;
                    line-height: 20px;
                    align-items: center;
                    justify-content: center;
                    margin: 40px 0 16px;
                  "
                >
                  <i
                    style="font-size: 20px; color: #15c089; margin-right: 8px"
                    class="el-icon-success"
                  ></i
                  >密码修改成功，请登录
                </p>
                <p
                  @click="passwordSuccess = false"
                  style="font-size: 14px; color: #2a72f4; cursor: pointer"
                >
                  返回登录<i
                    class="el-icon-right"
                    style="
                      border: 1px solid #2a72f4;
                      border-radius: 50%;
                      font-size: 12px;
                      margin-left: 8px;
                    "
                  ></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="shopping_cart el-backtop" @click="toShoppingCart">
        <i class="el-icon-shopping-cart-2"></i>
        <span class="num">{{ quantity > 99 ? "99+" : quantity }}</span>
      </div>
      <!-- <form
        action="https://epaytest.jsbchina.cn/epcs/newmallpubcashier/loginNobind.htm"
        method="post"
      >
        <input :name="e.key" :value="e.value" v-for="(e, i) in t" :key="i" />
        <button type="submit">提交</button>
      </form> -->
      <!-- <div class="side_function_bar">
      <ul>
        <li class="item">
          <svg-icon icon-class="zx_icon" class="item_icon" />
          <div class="hidden_item">问题咨询</div>
        </li>
        <li class="item">
          <svg-icon icon-class="cjwt_icon" class="item_icon" />
          <div class="hidden_item">常见问题</div>
        </li>
        <li class="item" @click="toShoppingCart">
          <svg-icon icon-class="gwc_icon" class="item_icon" />
          <div class="hidden_item">购物车</div>
        </li>
        <li class="item">
          <svg-icon icon-class="ewm_icon" class="item_icon" />
          <div class="wx_hidden_item">
            <img src="@/assets/images/wxkf.png" width="126" height="126" />
            <p>微信扫二维码</p>
            <p>关注公众平台</p>
          </div>
        </li>
        <li class="item" @click="scrollToTop">
          <svg-icon icon-class="zd_icon" class="item_icon" />
          <div class="hidden_item">回到顶部</div>
        </li>
      </ul>
    </div>
     -->
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { setToken, removeToken } from "@/utils/auth";

export default {
  data() {
    var user = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("账号不能为空"));
      } else if (!new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/).test(value)) {
        return callback(new Error("账号格式错误"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (this.mobileLogin) callback();
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6) {
        callback(new Error("密码最少为6位"));
      } else {
        callback();
      }
    };
    var validateauthCode = (rule, value, callback) => {
      if (
        !this.mobileLogin &&
        !this.registerLoginShow &&
        !this.retrievePassword
      )
        callback();

      if (value === "") {
        return callback(new Error("验证码不能为空"));
      } else if (value.length < 6) {
        callback(new Error("验证码为6位"));
      } else {
        callback();
      }
    };
    return {
      select: "1",
      searchVal: "",

      ruleForm: {
        user: "",
        pass: "",
        authCode: "", //验证码
      },
      rules: {
        user: [{ validator: user, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        authCode: [{ validator: validateauthCode, trigger: "blur" }],
      },
      registerLoginShow: false,
      mobileLogin: false,
      clause: false,
      retrievePassword: false,
      passwordSuccess: false,
      btnLoading: false,
      countdown: 0,
      sort: [],
      navList: [],

      scrollTop: 0, //默认距离顶部的距离
      scrollTrigger: false, //默认初始值

      t: [],
    };
  },
  computed: {
    ...mapState(["loginShow", "isLogin", "userInfo", "quantity"]),
  },
  watch: {
    loginShow(val) {
      if (val) {
        this.$refs.ruleForm.resetFields();
        this.retrievePassword = false;
        this.mobileLogin = false;
      }
    },
    retrievePassword(val) {
      this.$refs.ruleForm.resetFields();
    },
    registerLoginShow(val) {
      this.$refs.ruleForm.resetFields();
    },
    passwordSuccess(val) {
      if (!val) {
        this.$refs.ruleForm.resetFields();
        this.retrievePassword = false;
      }
    },
    $route(val) {
      let path = val.path.split("/");
      if (path.length > 1) path = path[1];
      if (
        path === "jingdezhenCeramics" &&
        this.navList.find((e) => e.path === "/fashionPlay")
      ) {
        this.navList[3].text = "数字博物馆";
        this.navList.splice(6, 1);
      } else if (
        !this.navList.find((e) => e.path === "/fashionPlay") &&
        sessionStorage.getItem("source") !== "1" &&
        (val.name === "artwork-list" || val.name === "trading-hall")
      ) {
        this.navList[3].text = "百馆珍藏";
        this.navList.splice(6, 0, {
          text: "潮玩元宇宙",
          path: "/fashionPlay",
        });
      }
      if (val.query.search) {
        this.searchVal = val.query.search;
      } else {
        this.searchVal = "";
      }
    },
  },
  created() {
    let list = [
      {
        text: "首页",
        path: "/homePage",

        sub: [
          {
            text: "新闻资讯",
            path: "/news",
          },
          {
            text: "展览与活动",
            path: "/exhibitionActivity",
          },
          {
            text: "合作与交流",
            path: "/cooperationAndExchange",
          },
        ],
      },
      {
        text: "文化广场",
        path: "/artworkList",
        // sub: [],
        sub: [
          {
            text: "艺术品交易",
            path: "",
          },
          // {
          //   text: "版权交易",
          //   path: "",
          // },
          {
            text: "艺术机构",
            path: "/artInstitutionList",
          },
        ],
      },
      {
        text: "交易大厅",
        path: "/tradingHall",
      },
      {
        text: "百馆珍藏",
        path: "/bgzc",
        sub: [
          {
            text: "AI展示",
            path: "/bgzc",
          },
          {
            text: "我要文创",
            path: "/bgzc",
          },
          // {
          //   text: "艺术家名录",
          //   path: "/artistList",
          // },
          // {
          //   text: "艺术家趣闻",
          //   path: "/interestingNews",
          // },
          // {
          //   text: "我要上直播",
          //   path: "/LiveRegistration",
          // },
        ],
      },
      {
        text: "拍卖",
        path: "/auction",
        sub: [
          {
            text: "深文交拍卖行",
            path: "/auction",
          },
          {
            text: "其他拍卖行",
            path: "",
          },
          {
            text: "拍卖信息",
            path: "/auctionInfo",
            login: true,
          },
        ],
      },
      {
        text: "交易认证",
        path: "/transactionAuthentication",
      },
      {
        text: "潮玩元宇宙",
        path: "/fashionPlay",
      },
      {
        text: "景德镇陶瓷",
        path: "/jingdezhenCeramics",
        // sub: [],
        // sub: [
        // {
        //   text: "机构名录",
        //   path: "/artInstitutionList",
        // },
        // {
        //   text: "机构资讯",
        //   path: "/organizationInformation",
        // },
        // ],
      },
    ];

    if (sessionStorage.getItem("source") === "1") {
      list[3].text = "数字博物馆";
      list.splice(6, 1);
    }

    this.navList = list;

    let a =  {"charset":"UTF-8","publicKeyCode":"00","channelNo":"m","subject":"%E6%B5%8B%E8%AF%95%E6%94%AF%E4%BB%98","bizDate":"20240930","sign":"amount=0.01&bizDate=20240930&body=%E5%86%85%E5%AE%B9&channelNo=m&charset=UTF-8&createDate=20240930&createTime=090637&currency=CNY&field1=&field2=&field3=&field4=&field5=&field6=&jumpConfig=&jumpSec=5&msgID=20240930090637713106&notifyUrl=https%3A%2F%2Fconsumer.realbrand.net%2Fpay-server%2Fnotify%2FnopswPaynotify&outerOrderNo=pay20240930001&partnerBuyerId=test200409290001&partnerId=3893d11c7ced459489ac2239b27f7b80&publicKeyCode=00&returnUrl=&service=nopwdCreateOrder&subject=%E6%B5%8B%E8%AF%95%E6%94%AF%E4%BB%98&svrCode=&version=v1.0.0&signType=RSA&sign=Xb+XIwvQJtRhCRZzGniZzBvl/ajkZBVMYzC++z64r88qJ45UV1xJgffyWsXgMEVlSxvXb3bHFGee7vZtmBMDAk9NQuAXNiBkiSzdjl9nS262amaH0PmwdMEPrrES2Otlnw5VP0/X43xpMKudaUizLlDpEqKA3Rc3+6Q0asawvZM=","body":"%E5%86%85%E5%AE%B9","svrCode":"","signType":"RSA","currency":"CNY","returnUrl":"","partnerBuyerId":"test200409290001","createDate":"20240930","amount":"0.01","msgID":"20240930090637713106","outerOrderNo":"pay20240930001","jumpSec":"5","version":"v1.0.0","field1":"","createTime":"090637","service":"nopwdCreateOrder","notifyUrl":"https%3A%2F%2Fconsumer.realbrand.net%2Fpay-server%2Fnotify%2FnopswPaynotify","field6":"","partnerId":"3893d11c7ced459489ac2239b27f7b80","field3":"","field2":"","jumpConfig":"","field5":"","field4":""}

    this.t = Object.keys(a).map((key) => ({ key, value: a[key] }));
    console.log(this.t);
  },
  mounted() {
    // this.getClassification();
    this.listenerFunction();
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },

  methods: {
    listenerFunction(e) {
      document.addEventListener("scroll", this.getScrollTop, true);
    },
    getScrollTop(e) {
      this.scrollTop = window.pageYOffset;
    },
    scrollToTop() {
      // 防止频繁点击，故返回顶部后设置scrollTrigger为初始值
      if (this.scrollTrigger) {
        return;
      }
      // 获取当前距离顶部的距离
      let scrollTop = this.scrollTop;
      let steep = scrollTop / 2000;
      let timer = setInterval(() => {
        this.scrollTrigger = true;
        // 滚动的速度逐渐变快，第一次走2000/1，然后减去已走的距离，下一次用剩下的距离再减去步进值，步进值也是不断变化，这样速度会越来越快
        scrollTop -= steep;
        // 步进值不改变的话会匀速缓慢上滑，不断增加步进值上滑的距离增加，视觉效果速度变快
        steep += 45;
        if (scrollTop <= 0) {
          clearInterval(timer);
          this.scrollTrigger = false;
        }
        document.documentElement.scrollTop = scrollTop;
        document.body.scrollTop = scrollTop;
      }, 30);
    },

    toSearch() {
      let path = "";
      switch (this.select) {
        case "1":
          path = "/artworkList";
          break;
        case "2":
          path = "/artistList";
          break;
        case "3":
          path = "/artInstitutionList";
          break;
        default:
          break;
      }
      if (
        this.searchVal === this.$route.query.search &&
        this.$route.name === path
      )
        return;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.$router.push({
          path: path,
          query: {
            search: this.searchVal ? this.searchVal : undefined,
          },
        });
      }, 300);
    },
    toSort(val) {
      this.$router.push({
        path: "/sort",
        query: {
          categoryId: val.categoryId,
          categoryName: val.categoryName,
        },
      });
    },
    async getClassification() {
      await this.$API.all
        .queryCategoriesCatalog({
          object: {
            enterpriseId: "",
          },
        })
        .then((res) => {
          if (!res) return;
          this.sort = res.data.data.categoriesCatalog;

          function flattenObject(obj) {
            const result = [];

            function traverse(item) {
              if (Array.isArray(item)) {
                item.forEach(traverse);
              } else if (item && typeof item === "object") {
                if (item.categoryId) {
                  result.push(item.categoryId);
                }
                if (Array.isArray(item.subItemList)) {
                  item.subItemList.forEach(traverse);
                }
              }
            }

            traverse(obj);

            return result;
          }

          const output = this.sort.map(flattenObject);

          this.sort.forEach((e, i) => {
            this.navList[1].sub.push({
              text: e.categoryName,
              categoryId: output[i],
            });
          });
        });
    },
    submitForm(formName) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (this.retrievePassword) {
              this.updatePasswd();
              return;
            }

            await this.$API.login
              .login({
                password: this.mobileLogin
                  ? this.ruleForm.authCode
                  : this.ruleForm.pass,
                username: this.ruleForm.user,
              })
              .then(async (res) => {
                if (!res) return;
                setToken(res.data.data.token);
                var t = false;
                await this.$API.user
                  .findUserById({
                    object: {
                      id: res.data.data.id,
                    },
                  })
                  .then((res) => {
                    if (!res) return;
                    let data = res.data.data.userInfo;
                    if (!data.userEntity.activeState) {
                      this.$message({
                        message:
                          "该手机号尚未注册，请通过平台会员推荐注册，并开通会员权益！",
                        type: "error",
                      });
                      t = true;
                      removeToken();
                    }
                  });

                if (t) return;

                await this.$store.dispatch("getUser");

                this.$message({
                  message: "登录成功",
                  type: "success",
                });
                this.$store.commit("loginShowSwitch");
                clearInterval(this.t);
                this.countdown = 0;
                this.mobileLogin = false;
                for (const key in this.ruleForm) {
                  this.ruleForm[key] = "";
                }
                if (this.$route.name === "artwork") this.$router.go(0);
              });
          } else {
            return false;
          }
        });
      }, 300);
    },
    register(formName) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        if (!this.clause) {
          this.$message({
            message: "请勾选服务条款",
            type: "warning",
          });
          return;
        }
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            await this.$API.login
              .register({
                object: {
                  telNum: this.ruleForm.user,
                  password: this.ruleForm.pass,
                  captcha: this.ruleForm.authCode,
                  userType: "CONSUMER",
                  // ENTERPRISE, 企业
                  // PLATFORM, 平台
                  // CONSUMER, 消费者
                  // PURCHASE, 店长
                },
              })
              .then((res) => {
                if (!res) return;
                this.registerLoginShow = false;
                this.mobileLogin = false;
                this.$message({
                  message: "注册成功",
                  type: "success",
                });
              });
          } else {
            return false;
          }
        });
      }, 300);
    },
    captchaMethod() {
      if (!this.countdown) {
        this.$refs.ruleForm.validateField(["user"], async (valid) => {
          if (!valid && !this.countdown) {
            await this.$API.login
              .sendCaptchaSms({
                mobile: this.ruleForm.user,
              })
              .then((res) => {
                if (!res) return;
                this.$message({
                  message: "发送成功",
                  type: "success",
                });
                let t = 59;
                this.countdown = `${t}s后重新获取`;
                this.t = setInterval(() => {
                  t--;
                  this.countdown = `${t}s后重新获取`;
                  if (t < 1) {
                    clearInterval(this.t);
                    this.countdown = false;
                  }
                }, 1000);
              });
          }
        });
      }
    },
    handleCommand(command) {
      if (command === "updatePassword") {
      } else if (command === "loginOut") {
        this.$store.commit("LoginOut");
        this.$router.replace("/homePage");
        this.$message({
          message: "退出成功",
          type: "success",
        });
      }
    },
    toShoppingCart() {
      if (!this.isLogin) {
        this.$store.commit("loginShowSwitch");
        return;
      }

      let url = this.$router.resolve("/shoppingCart");
      window.open(url.href, "_blank");
    },

    navSkip(val) {
      if (val.login && !this.isLogin) {
        this.$store.commit("loginShowSwitch");
        return;
      }
      // if (val.categoryId) {
      //   this.$router.push({
      //     path: "/artworkList",
      //     query: {
      //       categoryId: JSON.stringify(val.categoryId),
      //       // categoryName: val.text,
      //     },
      //   });
      // } else

      if (val.path) {
        this.$router.push({
          path: val.path,
        });
      }
    },

    async updatePasswd() {
      this.btnLoading = true;
      await this.$API.user
        .updatePasswdByUserName({
          object: {
            // captcha: this.ruleForm.authCode,
            captcha: this.ruleForm.authCode,
            password: this.ruleForm.pass,
            username: this.ruleForm.user,
          },
        })
        .then((res) => {
          if (!res) return;
          this.passwordSuccess = true;
        });
      this.btnLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.app_header {
  .top {
    padding: 8px 0;
    background: #d40f1f;
    font-size: 12px;
    color: #fff;
    min-height: 40px;
    > div {
      min-height: 22px;
    }
    .left {
      ::v-deep .el-select {
        .el-input__inner {
          background: transparent;
          border: none;
          font-size: 12px;
          padding: 0 4px;
          height: 22px;
          line-height: 22px;
          width: 102px;
        }
        .el-input__suffix {
          .el-input__icon {
            line-height: 1;
          }
        }
      }
    }
    .list {
      display: flex;
      align-items: center;
      a {
        color: #fff;
      }
      .user {
        display: flex;
        align-items: center;
        cursor: pointer;
        a {
          font-size: 12px;
          color: #fff;
        }
        img {
          width: 24px;
          height: 24px;
          margin-left: 10px;
        }
        .svg-icon {
          margin-left: 5px;
          font-size: 8px;
          color: #fff;
        }
      }
      li::after {
        content: "|";
        margin: 0 16px;
      }
      li:last-child::after {
        display: none;
      }
      span {
        cursor: pointer;
      }
      // span:hover {
      //   color: var(--subjectColor);
      // }
    }
  }
  .middle {
    margin: 30px auto;
    .logo {
      cursor: pointer;
      display: flex;
      img {
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #c90707;
        h1 {
          font-size: 16px;
          letter-spacing: 0.5px;
          b {
            font-size: 12px;
            line-height: 9px;
            vertical-align: top;
          }
        }
        h4 {
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0.5px;
        }
      }
    }
    ::v-deep .el-dropdown {
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      .el-button-group {
        margin-top: 2px;
      }
      a {
        font-size: 18px;
        color: #333333;
        // font-weight: bold;
        position: relative;
      }
      a::after {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 0;
        height: 2px;
        width: 0px;
        background: var(--subjectColor);
        transition: 0.2s all;
      }
      .router-link-active::after {
        content: "";
        position: absolute;
        bottom: -6px;
        height: 3px;
        width: 100%;
        background: var(--subjectColor);
      }
      .router-link-active {
        color: var(--subjectColor);
      }
      .el-button {
        border: none !important;
        background: #fff !important;
        padding-left: 0;
        padding-right: 0;
      }
      .el-button:hover {
        background: #fff;
      }
      .el-dropdown__caret-button {
        display: none;
      }
    }
    ::v-deep .search {
      .el-input-group__prepend {
        background-color: #fff;
        color: #666;
        border: 1px solid #e5e5e5;
        border-right: none;

        // border-radius: 0;
        .el-select .el-input {
          width: 92px;
        }
        .el-input__suffix {
          right: 0;
          .el-select__caret {
            line-height: 36px;
          }
        }
        .el-input__icon::before {
          content: "\e78f";
          color: #666;
        }
        .el-input__inner {
          padding-left: 12px;
          padding-right: 0;
        }
      }

      .input-with-select > .el-input__inner {
        width: 100px;
        border: 1px solid #e5e5e5;
        border-right: none;
        border-left: none;
        padding: 0;
      }
      .input-with-select > .el-input__inner:focus {
        border-color: #e5e5e5;
      }
      .el-input__inner {
        height: 36px;
      }
      .el-input-group__append {
        background: #fff;
        border: 1px solid #e5e5e5;
        color: #e5e5e5;
        font-size: 16px;
        // border-radius: 0;
        width: 36px;
        height: 36px;
        padding: 0;
        border-left: none;
        text-align: center;
        .el-button {
          padding: 8px;
          margin: 0;
        }
      }
    }
  }
  // .bottom {
  //   display: flex;
  //   .sort {
  //     .el-dropdown {
  //       margin-right: 90px;
  //       > p {
  //         width: 264px;
  //         background: var(--subjectColor);
  //         display: block;
  //         color: #fff;
  //         line-height: 42px;
  //         text-align: center;
  //         font-size: 16px;
  //         cursor: pointer;
  //       }
  //     }
  //   }
  //   .nav {
  //     display: flex;
  //     align-items: center;
  //     ::v-deep .el-dropdown {
  //       // margin: 0 38px;
  //       margin: 0 25px;
  //       font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  //       // padding-bottom: 6px;
  //       a {
  //         font-size: 18px;
  //         color: #333333;
  //         font-weight: bold;
  //         position: relative;
  //       }
  //       a::after {
  //         content: "";
  //         position: absolute;
  //         bottom: -6px;
  //         left: 0;
  //         height: 3px;
  //         width: 0px;
  //         background: var(--subjectColor);
  //         transition: 0.2s all;
  //       }
  //       .router-link-active::after {
  //         content: "";
  //         position: absolute;
  //         bottom: -6px;
  //         height: 3px;
  //         width: 100%;
  //         background: var(--subjectColor);
  //       }
  //       .router-link-active {
  //         color: var(--subjectColor);
  //       }
  //       .el-button {
  //         border: none !important;
  //         background: #fff !important;
  //         padding-left: 0;
  //         padding-right: 0;
  //       }
  //       .el-button:hover {
  //         background: #fff;
  //       }
  //       .el-dropdown__caret-button {
  //         display: none;
  //       }
  //     }
  //     .nav_login {
  //       margin: 0 25px;
  //       font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  //       padding-bottom: 2px;
  //       cursor: pointer;
  //       font-size: 18px;
  //       color: #333333;
  //       font-weight: bold;
  //     }
  //   }
  // }
}
.app_footer {
  background: var(--subjectColor);
  color: #fff;
  .top {
    display: flex;

    padding: 20px 0;
    border-bottom: 1px solid #fff;
    .swc {
      margin-right: 30px;
      display: flex;
      align-items: center;
      text-align: center;
      p {
        line-height: 22px;
        font-size: 16px;
      }
    }
    .ewm {
      margin-right: 20px;
      text-align: center;

      p {
        padding: 5px 7px;
        background: #bfbfbf;
        color: #fff;
        border-radius: 99px;
        font-size: 12px;
        margin-top: 8px;
      }
    }
    ul {
      display: flex;
      font-size: 12px;
      margin-left: 40px;
      flex: 1;
      justify-content: space-between;
      a {
        color: #fff;
      }
      dt {
        font-size: 14px;
        margin-bottom: 24px;
      }
      dd {
        margin-bottom: 15px;
      }
      dd:last-child {
        margin: 0;
      }
    }
  }
  .bottom {
    padding: 22px 0;
    text-align: center;
    font-size: 12px;
  }
}
.login_box {
  position: fixed;
  top: 0;
  width: 100%;
  bottom: 0;
  background: rgba($color: #333, $alpha: 0.48);
  z-index: 2002;
  display: flex;
  align-items: center;
  justify-content: center;

  .login {
    background: #fff;
    border-radius: 10px;
    display: flex;
    height: 540px;

    .left {
      color: #fff;
      background: url("../src/assets/images/login.png");
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 64px;
        height: 64px;
        padding: 9px;
        border-radius: 4px;
        margin-bottom: 24px;
        background: #fff;
      }
      h2 {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      h3 {
        font-size: 12px;
        line-height: 13px;
      }
    }
    .right {
      padding: 40px 36px;
      width: 420px;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      position: relative;
      .el-icon-close {
        position: absolute;
        right: 30px;
        top: 20px;
        font-size: 26px;
        color: #999;
        cursor: pointer;
      }
      h2 {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 24px;
      }
      ::v-deep .el-form {
        padding-top: 8px;

        .el-form-item {
          margin-bottom: 24px;
          .el-form-item__content {
            line-height: 54px;
            .el-input__inner {
              height: 54px;
            }
            .el-button {
              border-color: var(--subjectColor);
              background: var(--subjectColor);
              font-size: 16px;
            }
            .el-input-group__append {
              padding: 0;
              .code {
                cursor: pointer;
                width: 100%;
                line-height: 52px;
                padding: 0 20px;
              }
            }
            .el-input__inner:focus {
              border-color: var(--subjectColor);
            }
          }
        }
        .clause {
          cursor: pointer;
          font-size: 12px;
          display: flex;
          align-items: flex-start;
          margin-bottom: 16px;
          input[type="checkbox"] {
            margin: 1px;
            cursor: pointer;
          }
          div {
            line-height: 17px;
            color: #999;
            margin-left: 8px;
            a {
              color: #2a72f4;
            }
            a:hover {
              text-decoration: underline;
            }
          }
          // display: flex;
        }
        .register {
          background-color: var(--subjectColor);
          border-color: var(--subjectColor);
          transition: 0.2s all;
        }
      }
      .toggle {
        p {
          font-size: 12px;
          line-height: 12px;
          color: #999999;
          a {
            cursor: pointer;
            color: #5677fc;
          }
        }
        .el-button {
          margin-top: 16px;
          margin-left: 0;
          background: #ffe8e8;
          border-color: #ffe8e8;
          color: #d40f1f;
          font-size: 16px;
        }
      }
      .forgot {
        color: #999;
        font-size: 12px;
        cursor: pointer;
        margin-top: 16px;
      }
      .el-button--primary {
        width: 100%;
        height: 54px;
      }
    }
  }
}

.el-backtop {
  width: 60px !important;
  height: 60px !important;
  z-index: 2001 !important;
}
.shopping_cart {
  right: 40px;
  bottom: 40px;
  font-size: 24px !important;
  .num {
    position: absolute;
    right: -4px;
    top: -4px;
    color: #fff;
    font-size: 13px;
    line-height: 22px;
    background: red;
    height: 22px;
    min-width: 22px;
    border-radius: 22px;
    padding: 0 4px;
    text-align: center;
  }
}

.side_function_bar {
  position: fixed;
  right: 0;
  top: 35%;
  z-index: 101;

  .item {
    position: relative;
    cursor: pointer;
    > div {
      background: #fff;
      color: #666666;
      z-index: 100;
      left: 0;
      transition: 0.2s all;
      z-index: 100;
    }
  }
  .item:last-child {
    margin: 0;
  }
  .item_icon {
    font-size: 20px;
    position: relative;
    z-index: 101;
    background: #333;
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .hidden_item {
    position: absolute;
    top: 0;
    width: 80px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .hidden_item:hover {
    background: var(--subjectColor);
    color: #fff;
  }
  .item:hover {
    .hidden_item {
      left: -80px;
      box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.16);
    }
    .wx_hidden_item {
      left: -140px;
      box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.16);
    }
  }
  .wx_hidden_item {
    position: absolute;
    width: 140px;
    height: 200px;
    top: -120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 10px;
  }
}
</style>

<style lang="scss">
.user_info_menu {
  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: var(--subjectColor);
    background-color: #f5f7fa;
  }
  .el-dropdown-menu__item {
    text-align: center;
  }
  .user_info {
    padding: 0 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 54px;
      height: 54px;
      margin-bottom: 14px;
    }
    span {
      font-size: 16px;
      color: #333;
      margin-bottom: 14px;
    }
  }
}
.sort_menu {
  width: 264px;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  .el-dropdown-menu__item {
    line-height: 60px;
    background: rgba($color: #333, $alpha: 0.75);
    color: #fff;
    text-align: center;
  }
  .el-dropdown-menu__item:hover {
    color: var(--subjectColor) !important;
  }
  .popper__arrow {
    display: none !important;
  }
}
.nav_menu {
  .el-dropdown-menu__item {
    color: #999;
    font-weight: bold;
    font-size: 14px;
  }
  .el-dropdown-menu__item:hover {
    color: var(--subjectColor) !important;
  }
  .popper__arrow {
    display: none !important;
  }
}
.el-select-dropdown__item.selected {
  color: var(--subjectColor) !important;
}
.el-message {
  z-index: 999999999999 !important;
}
</style>
