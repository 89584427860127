import request from '@/utils/userRequest'//20050

//购物车总数
export async function getShoppingCartSum(data) {
  return await request({
    url: '/consumer-shopping-server/Order/OrderCartTotal',
    method: 'post',
    data
  })
}

//添加购物车
export async function insertCusOrderCart(data) {
  return await request({
    url: '/consumer-shopping-server/Order/InsertCusOrderCart',
    method: 'post',
    data
  })
}

//删除购物车
export async function deleteOrderCart(data) {
  return await request({
    url: '/consumer-shopping-server/Order/DeleteOrderCart',
    method: 'post',
    data
  })
}

//修改购物车数量
export async function updateOrderCart(data) {
  return await request({
    url: '/consumer-shopping-server/Order/UpdateOrderCart',
    method: 'post',
    data
  })
}

//购物车列表
export async function orderCartList(data) {
  return await request({
    url: '/consumer-shopping-server/Order/OrderCartList',
    method: 'post',
    data
  })
}

//形成订单
export async function transactionOrder(data) {
  return await request({
    url: '/consumer-shopping-server/Order/TransactionOrder',
    method: 'post',
    data
  })
}

//订单详情
export async function dealDetails(data) {
  return await request({
    url: '/consumer-shopping-server/Order/DealDetails',
    method: 'post',
    data
  })
}

//总订单详情
export async function totalDealDetails(data) {
  return await request({
    url: '/consumer-shopping-server/Order/TotalDealDetails',
    method: 'post',
    data
  })
}

//订单列表
export async function closedOrderList(data) {
  return await request({
    url: '/consumer-shopping-server/Order/ClosedOrderList',
    method: 'post',
    data
  })
}

//修改订单状态
export async function UpdateDealStatus(data) {
  return await request({
    url: '/consumer-shopping-server/Order/UpdateDealStatus',
    method: 'post',
    data
  })
}

//确认收货
export async function receiving(data) {
  return await request({
    url: '/consumer-shopping-server/Order/receiving',
    method: 'post',
    data
  })
}

//取消订单
export async function cancelOrder(data) {
  return await request({
    url: '/consumer-shopping-server/Order/CancelOrder',
    method: 'post',
    data
  })
}

//删除订单
export async function DeleteOrder(data) {
  return await request({
    url: '/consumer-shopping-server/Order/DeleteOrder',
    method: 'post',
    data
  })
}

//运单详情
export async function waybillDetails(data) {
  return await request({
    url: '/consumer-shopping-server/CusOrder/queryRoute',
    method: 'post',
    data
  })
}

//支付二维码
export async function orderPay(data) {
  return await request({
    url: '/consumer-shopping-server/Payment/orderPay',
    method: 'post',
    data
  })
}

//支付查询
export async function bankPayQuery(data) {
  return await request({
    url: '/consumer-shopping-server/Payment/bankPayQuery',
    method: 'post',
    data
  })
}

//形成订单扣除礼券
export async function orderConsumeTokens(data) {
  return await request({
    url: '/consumer-shopping-server/Order/OrderConsumeTokens',
    method: 'post',
    data
  })
}

//待入账明细
export async function closedOrderTotal(data) {
  return await request({
    url: '/consumer-shopping-server/CusOrder/ClosedOrderTotal',
    method: 'post',
    data
  })
}

//待入账明细列表
export async function cusClosedOrderList(data) {
  return await request({
    url: '/consumer-shopping-server/CusOrder/ClosedOrderList',
    method: 'post',
    data
  })
}

//待入账明细列表
export async function commissionRecordInfo(data) {
  return await request({
    url: '/consumer-shopping-server/Order/commissionRecordInfo',
    method: 'post',
    data
  })
}

//提现
export async function withdrawalCreate(data) {
  return await request({
    url: '/consumer-shopping-server/Payment/withdrawalCreate',
    method: 'post',
    data
  })
}

//查询提现记录
export async function withdrawalQuery(data) {
  return await request({
    url: '/consumer-shopping-server/Payment/withdrawalQuery',
    method: 'post',
    data
  })
}


