import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getToken } from "@/utils/auth"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/homePage',
  },
  {
    path: '/homePage',
    name: 'homePage',
    component: () => import(/* webpackChunkName: "homePage" */ '@/views/HomePage')
  },
  {
    path: '/sort',
    name: 'sort',
    component: () => import(/* webpackChunkName: "Sort" */ '@/views/Sort')
  },
  {
    path: '/artworkList',
    name: 'artwork-list',
    component: () => import(/* webpackChunkName: "ArtworkList" */ '@/views/ArtworkList')
  },
  // {
  //   path: '/artworkList2',
  //   name: 'artworkList',
  //   component: () => import(/* webpackChunkName: "ArtworkList" */ '@/views/ArtworkList/index2')
  // },
  {
    path: '/artwork',
    name: 'artwork',
    component: () => import(/* webpackChunkName: "Artwork" */ '@/views/Artwork')
  },
  {
    path: '/artistList',
    name: 'artistList',
    component: () => import(/* webpackChunkName: "ArtistList" */ '@/views/ArtistList')
  },
  {
    path: '/bgzc',
    name: 'bgzc',
    component: () => import(/* webpackChunkName: "bgzc" */ '@/views/bgzc')
  },
  {
    path: '/artist',
    name: 'artist',
    component: () => import(/* webpackChunkName: "Artist" */ '@/views/Artist')
  },
  {
    path: '/artInstitutionList',
    name: 'artInstitutionList',
    component: () => import(/* webpackChunkName: "ArtInstitutionList" */ '@/views/ArtInstitutionList'),
  },
  {
    path: '/artInstitution',
    name: 'artInstitution',
    component: () => import(/* webpackChunkName: "ArtInstitution" */ '@/views/ArtInstitution')
  },
  {
    path: '/jingdezhenCeramics',
    name: 'jingdezhenCeramics',
    component: () => import(/* webpackChunkName: "JingdezhenCeramics" */ '@/views/JingdezhenCeramics'),
    redirect: '/jingdezhenCeramics/homePage',
    children: [
      {
        path: 'homePage',
        name: 'jdz-home-page',
        component: () => import(/* webpackChunkName:'JdzHomePage' */'@/views/JingdezhenCeramics/homePage'),
      },
      {
        path: 'trading',
        name: 'trading',
        component: () => import(/* webpackChunkName:'Trading' */'@/views/JingdezhenCeramics/trading'),
      },
      {
        path: 'ceramicMarket',
        name: 'ceramic-market',
        component: () => import(/* webpackChunkName:'CeramicMarket' */'@/views/JingdezhenCeramics/ceramicMarket'),
      },
      {
        path: 'marketService',
        name: 'market-service',
        component: () => import(/* webpackChunkName:'MarketService' */'@/views/JingdezhenCeramics/marketService'),
      },
      {
        path: 'aboutThePlatform',
        name: 'about-the-platform',

        component: () => import(/* webpackChunkName:'AboutThePlatform' */'@/views/JingdezhenCeramics/aboutThePlatform'),
      },

    ]
  },
  {
    path: '/auction',
    name: 'Auction',
    component: () => import(/* webpackChunkName: "Auction" */ '@/views/Auction')
  },
  {
    path: '/auctionInfo',
    name: 'auctionInfo',
    component: () => import(/* webpackChunkName: "AuctionInfo" */ '@/views/Auction/AuctionInfo'),
    redirect: '/auctionInfo/myAuction',
    children: [
      {
        path: 'myAuction',
        name: 'my-auction',
        component: () => import(/* webpackChunkName:'MyAuction' */'@/views/Auction/AuctionInfo/MyAuction'),
      },
      {
        path: 'myAuction/auctionDetails',
        name: 'auction-details',
        component: () => import(/* webpackChunkName:'AuctionDetails' */'@/views/Auction/AuctionInfo/MyAuction/AuctionDetails'),
      },
      {
        path: 'myAuction/auctionInfo',
        name: 'auction-info',
        component: () => import(/* webpackChunkName:'AuctionInfo' */'@/views/Auction/AuctionInfo/MyAuction/AuctionInfo'),
      },
      {
        path: 'myOrders',
        name: 'my-orders',
        component: () => import(/* webpackChunkName:'MyOrders' */'@/views/Auction/AuctionInfo/MyOrders'),
      },
      {
        path: 'myOrders/orderDetails',
        name: 'order-etails',
        component: () => import(/* webpackChunkName:'OrderDetails' */'@/views/Auction/AuctionInfo/MyOrders/OrderDetails'),
      },

      {
        path: 'myWallet',
        name: 'my-wallet',
        component: () => import(/* webpackChunkName:'MyWallet' */'@/views/Auction/AuctionInfo/MyWallet'),
      },
      {
        path: 'myWallet/redFlower',
        name: 'red-flower',
        component: () => import(/* webpackChunkName:'RedFlower' */'@/views/Auction/AuctionInfo/MyWallet/redFlower'),
      },
      {
        path: 'myWallet/consumptionCoupon',
        name: 'consumption-coupon',
        component: () => import(/* webpackChunkName:'ConsumptionCoupon' */'@/views/Auction/AuctionInfo/MyWallet/consumptionCoupon'),
      },
      {
        path: 'myWallet/pendingEntry',
        name: 'pending-entry',
        component: () => import(/* webpackChunkName:'PendingEntry' */'@/views/Auction/AuctionInfo/MyWallet/pendingEntry'),
      },
      {
        path: 'myWallet/withdraw',
        name: 'withdraw',
        component: () => import(/* webpackChunkName:'Withdraw' */'@/views/Auction/AuctionInfo/MyWallet/withdraw'),
      },


      {
        path: 'realName',
        name: 'realName',
        component: () => import(/* webpackChunkName:'RealName' */'@/views/Auction/AuctionInfo/RealName'),
      },


    ]
  },

  {
    path: '/artZoneList',
    name: 'artZoneList',
    component: () => import(/* webpackChunkName: "ArtZoneList" */ '@/views/ArtZoneList')
  },
  {
    path: '/artZone',
    name: 'artZone',
    component: () => import(/* webpackChunkName: "ArtZone" */ '@/views/ArtZone')
  },
  {
    path: '/tradingHall',
    name: 'trading-hall',
    component: () => import(/* webpackChunkName: "TradingHall" */ '@/views/TradingHall')
  },
  {
    path: '/shoppingCart',
    name: 'shopping-cart',
    component: () => import(/* webpackChunkName: "ShoppingCart" */ '@/views/ShoppingCart')
  },
  {
    path: '/confirmAnOrder',
    name: 'confirm-an-order',
    component: () => import(/* webpackChunkName: "ConfirmAnOrder" */ '@/views/ConfirmAnOrder')
  },
  {
    //支付
    path: '/pay',
    name: 'pay',
    component: () => import(/* webpackChunkName:'Pay' */'@/views/Pay'),
    redirect: 'pay/modeOfPayment',
    children: [
      {
        path: 'modeOfPayment',
        name: 'mode-of-payment',
        component: () => import(/* webpackChunkName:'ModeOfPayment' */'@/views/Pay/ModeOfPayment'),
      },
      {
        path: 'cashier',
        name: 'cashier',
        component: () => import(/* webpackChunkName:'Cashier' */'@/views/Pay/Cashier'),
      },
      {
        path: 'completionOfPayment',
        name: 'completion-of-payment',
        component: () => import(/* webpackChunkName:'CompletionOfPayment' */'@/views/Pay/CompletionOfPayment'),
      },
      {
        path: 'noneOrder',
        name: 'none-order',
        component: () => import(/* webpackChunkName:'NoneOrder' */'@/views/Pay/NoneOrder'),
      }
    ]
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "Orders" */ '@/views/Orders')
  },
  {
    path: '/orderDetails',
    name: 'order-details',
    component: () => import(/* webpackChunkName: "OrderDetails" */ '@/views/Orders/orderDetails')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName:'News' */'@/views/News/NewsList'),
  }, {
    path: '/exhibitionActivity',
    name: 'exhibition-activity',
    component: () => import(/* webpackChunkName:'News' */'@/views/News/NewsList'),
  },
  {
    path: '/organizationInformation',
    name: 'organization-information',
    component: () => import(/* webpackChunkName:'News' */'@/views/News/NewsList'),
  },
  {
    path: '/interestingNews',
    name: 'interesting-news',
    component: () => import(/* webpackChunkName:'News' */'@/views/News/NewsList'),
  },
  {
    path: '/platformRules',
    name: 'platform-rules',
    component: () => import(/* webpackChunkName:'News' */'@/views/News/NewsList'),
  },
  {
    path: '/newsDetails',
    name: 'news-details',
    component: () => import(/* webpackChunkName:'NewsDetails' */'@/views/News/NewsDetails'),
  },
  {

    path: '/liveRegistration',
    name: 'live-registration',
    component: () => import(/* webpackChunkName:'LiveRegistration' */'@/views/LiveRegistration'),
  },

  {
    path: '/cooperationAndExchange',
    name: 'cooperation-and-exchange',
    component: () => import(/* webpackChunkName:'CooperationAndExchange' */'@/views/CooperationAndExchange'),
  },

  {
    path: '/transactionAuthentication',
    name: 'transaction-authentication',
    component: () => import(/* webpackChunkName:'TransactionAuthentication' */'@/views/TransactionAuthentication'),
  },
  {
    path: '/fashionPlay',
    name: 'fashion-play',
    component: () => import(/* webpackChunkName:'FashionPlay' */'@/views/FashionPlay'),
  },
  {
    path: '/contactUs',
    name: 'contact-us',
    component: () => import(/* webpackChunkName:'ContactUs' */'@/views/ContactUs'),
  },
  {
    path: "/zkzlpack",
    name: 'zkzlpack',
    component: () => import(/* webpackChunkName:'Zkzlpack' */'@/views/zkzlpack'),
    meta: {
      separatePage: true
    }
  },

  {
    path: "/fcm",
    name: 'fcm',
    component: () => import(/* webpackChunkName:'Zkzlpack' */'@/views/fcm'),
 
  },


  {
    path: '*',
    name: 'NotFound',
    component: () =>
      import( /* webpackChunkName:'NotFound’' */ '@/views/NotFound'),
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.query.source === '0') sessionStorage.setItem('source', '0')
  if (to.query.source === '1') sessionStorage.setItem('source', '1')

  if (getToken() && !store.state.isLogin) {
    await store.dispatch('getUser')
  }

  document.body.children[1].scrollTop = 0
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  next();
})

export default router
