import axios from 'axios'
import _this from '@/main.js'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url 
  // 20003
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})
service.interceptors.request.use(config => {
  config.headers['Region'] = "WH"
  // config.headers['Region'] = "QG"
  // config.headers['Region'] = "CS"


  return config
},
  err => {
    console.log(err)
    return Promise.reject(err)
  });

service.interceptors.response.use(response => {
  if (response.data.code !== "SUCCESS") {
    _this.$message.error(response.data.msg);
    return undefined
  } else {
    return response; // 有且必须有一个response对象被返回
  }
}, error => {
  console.log(error);
  // 对响应错误做点什么
  return Promise.reject(error);
});


export default service
