import axios from 'axios'
import { getToken } from "@/utils/auth"

import _this from '@/main.js'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_USER_API, // url = base url + request url 
    //20050

    timeout: 20000 // request timeout
})

service.interceptors.request.use(config => {
    // config.headers['Region'] = "QG"
    config.headers['Region'] = "WH"
    // config.headers['Region'] = "CS"

    const token = getToken()
    token && (config.headers.common['Authorization'] = `Bearer ${token}`)

    return config
},
    err => {
        console.log(err)
        return Promise.reject(err)
    });

service.interceptors.response.use(response => {
    if (response.status === 503) {
        _this.$message.error('请求服务报错');
        return undefined
    }
    else if (response.data.code !== "SUCCESS") {
        _this.$message.error(response.data.msg);
        return undefined
    } else {
        return response; // 有且必须有一个response对象被返回
    }
}, error => {
    console.log(error);
    // 对响应错误做点什么
    return Promise.reject(error);
});


export default service
