
import userRequest from '@/utils/userRequest'
import request from '@/utils/request'
//登录
export async function login(data) {
  return await userRequest({
    url: '/login',
    method: 'post',
    data
  })
}

//注册 
export async function register(data) {
  return await userRequest({
    url: '/consumer-user-server/User/registerUser',
    method: 'post',
    data
  })
}

//发送验证码
export async function sendCAPTCHA(data) {
  return await request({
    url: '/external-server/Message/sendCAPTCHA',
    method: 'post',
    data
  })
}

//WH发送验证码
export async function sendCaptchaSms(data) {
  return await request({
    url: '/external-server/SMS/sendCaptchaSms',
    method: 'post',
    data
  })
}


//验证验证码
export async function verificationCAPTCHA(data) {
	return await request({
		url: '/external-server/Message/verificationCAPTCHA',
		method: 'post',
		data
	})
}