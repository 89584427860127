import request from '@/utils/userRequest' //20050

//获取当前用户信息
export async function getUser(data) {
  return await request({
    url: '/consumer-user-server/User/getCurrUser',
    method: 'post',
    data
  })
}
//根据id获取用户信息
export async function findUserById(data) {
  return await request({
    url: '/consumer-user-server/User/findUserById',
    method: 'post',
    data
  })
}
//新增收货地址
export async function insertAddress(data) {
  return await request({
    url: '/consumer-user-server/Address/insertAddress',
    method: 'post',
    data
  })
}
//查询收货地址
export async function queryAddress(data) {
  return await request({
    url: '/consumer-user-server/Address/queryAddress',
    method: 'post',
    data
  })
}
//修改地址
export async function updateAddress(data) {
  return await request({
    url: '/consumer-user-server/Address/updateAddress',
    method: 'post',
    data
  })
}

//删除地址
export async function deleteAddress(data) {
  return await request({
    url: '/consumer-user-server/Address/deleteAddress',
    method: 'post',
    data
  })
}

//更新用户
export async function updateUser(data) {
  return await request({
    url: '/consumer-user-server/User/updateUser',
    method: 'post',
    data
  })
}


import testRequest from '@/utils/testRequest' //20050

//资金账户 
//个人开户
export async function cusApplications(data) {
  return await request({
    url: '/consumer-user-server/consumerUserFunds/cusApplications',
    method: 'post',
    data
  })
}
//银行短信
export async function sendSmsCodesByMobile(data) {
  return await request({
    url: '/consumer-user-server/consumerUserFunds/sendSmsCodesByMobile',
    method: 'post',
    data
  })
}
//查询开户状态
export async function queryApplications(data) {
  return await request({
    url: '/consumer-user-server/consumerUserFunds/queryApplications',
    method: 'post',
    data
  })
}
//查询资金账户信息
export async function queryElectronicAcct(data) {
  return await request({
    url: '/consumer-user-server/consumerUserFunds/queryElectronicAcct',
    method: 'post',
    data
  })
}

//查询明细记录
export async function payRevDetailsQuery(data) {
  return await request({
    url: '/consumer-shopping-server/Payment/payRevDetailsQuery',
    method: 'post',
    data
  })
}

//查询提现记录列表
export async function queryWithdrawalInfos(data) {
  return await request({
    url: '/consumer-shopping-server/Payment/queryWithdrawalInfos',
    method: 'post',
    data
  })
}

//查询银行卡列表
export async function selectSettleAcctList(data) {
  return await request({
    url: '/consumer-user-server/consumerUserFunds/selectSettleAcctList',
    method: 'post',
    data
  })
}
//查询个人进件信息
export async function queryCusApplicationsInfo(data) {
  return await request({
    url: '/consumer-user-server/consumerUserFunds/queryCusApplicationsInfo',
    method: 'post',
    data
  })
}

//实名认证
export async function verification(data) {
  return await request({
    url: '/consumer-shopping-server/Verification/verification',
    method: 'post',
    data
  })
}

//小红花明细
export async function userTokensRecordList(data) {
  return await request({
    url: '/consumer-user-server/Tokens/UserTokensRecordList',
    method: 'post',
    data
  })
}

//手机号修改密码
export async function updatePasswdByUserName(data) {
  return await request({
    url: '/consumer-user-server/User/updatePasswdByUserName',
    method: 'post',
    data
  })
}




//查询开户状态 //江苏
export async function queryAccountInfo(data) {
  return await request({
    url: '/consumer-shopping-server/babycareAccount/queryAccountInfo',
    method: 'post',
    data
  })
}

//查询余额
export async function queryJSaccount(data) {
  return await request({
    url: '/consumer-user-server/User/queryJSaccount',
    method: 'post',
    data
  })
}

//收支明细
export async function jsPayRevDetailsQuery(data) {
  return await request({
    url: '/consumer-shopping-server/jsBankPayment/jsPayRevDetailsQuery',
    method: 'post',
    data
  })
}

//提现
export async function buyerWithdrawal(data) {
  return await request({
    url: '/consumer-shopping-server/babycareAccount/buyerWithdrawal',
    method: 'post',
    data
  })
}

//开户
export async function mergeAccountNo(data) {
  return await request({
    url: '/consumer-shopping-server/babycareAccount/mergeAccountNo',
    method: 'post',
    data
  })
}

//提现记录
export async function withdrawalRecordInfos(data) {
  return await request({
    url: '/consumer-shopping-server/jsBankPayment/withdrawalRecordInfos',
    method: 'post',
    data
  })
}

