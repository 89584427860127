import request from '@/utils/request'

//企业首页
export async function enterprise(data) {
  return await request({
    url: '/business-server/HomePage/EnterpriseHomePage',
    method: 'post',
    data
  })
}

//首页店铺列表
export async function storeList(data) {
  return await request({
    url: '/business-server/HomePage/StoreList',
    method: 'post',
    data
  })
}
//店铺详情
export async function storeHomePage(data) {
  return await request({
    url: '/business-server/HomePage/StoreHomePage',
    method: 'post',
    data
  })
}

//首页活动
export async function getActivityList(data) {
  return await request({
    url: '/business-server/Activity/homeActivityList',
    method: 'post',
    data
  })
}

export async function getActivity(data) {
  return await request({
    url: '/business-server/Activity/homeActivity',
    method: 'post',
    data
  })
}
export async function getActivityComm(data) {
  return await request({
    url: '/business-server/Activity/homeActivityComm',
    method: 'post',
    data
  })
}


//作品
export async function commList(data) {
  return await request({
    url: '/business-server/HomePage/CommList',
    method: 'post',
    data
  })
}


export async function TradingFloor(data) {
  return await request({
    url: '/business-server/HomePage/TradingFloor',
    method: 'post',
    data
  })
}

//全部艺术家列表
export async function AllBrand(data) {
  return await request({
    url: '/business-server/HomePage/findBrandInfoList',
    method: 'post',
    data
  })
}

//艺术家总数
export async function findBrandTotal(data) {
  return await request({
    url: '/business-server/HomePage/findBrandTotal',
    method: 'post',
    data
  })
}

//推荐艺术家
export async function queryBrandList(data) {
  return await request({
    url: '/business-server/HomePage/queryBrandList',
    method: 'post',
    data
  })
}

//企业艺术家列表
export async function findCurrEnterpriseSellBrandInfoList(data) {
  return await request({
    url: '/business-server/Enterprise/findCurrEnterpriseSellBrandInfoList',
    method: 'post',
    data
  })
}

//作品详情
export async function commodityDetails(data) {
  return await request({
    url: '/business-server/CommodityMgt/CommodityDetails',
    method: 'post',
    data
  })
}

//作品拍卖记录
export async function auctionInfoDetails(data) {
  return await request({
    url: '/business-server/CommodityMgt/AuctionInfoDetails',
    method: 'post',
    data
  })
}


//作品价格详情
export async function commodityPriceDetails(data) {
  return await request({
    url: '/business-server/CommodityMgt/CommodityPriceDetails',
    method: 'post',
    data
  })
}

// 分类商品列表
export async function cultureCommList(data) {
  return await request({
    url: '/business-server/HomePage/CultureCommList',
    method: 'post',
    data
  })
}

// 分类品牌列表
export async function findCulBrandInfoList(data) {
  return await request({
    url: '/business-server/HomePage/findCulBrandInfoList',
    method: 'post',
    data
  })
}



import platformRequest from '@/utils/platformRequest.js'

//分类
export async function queryCategoriesCatalog(data) {
  return await request({
    url: '/business-server/Classify/queryCategoriesCatalogApi',
    method: 'post',
    data
  })
}

//新闻列表
export async function publishedNewsList(data) {
  return await request({
    url: '/business-server/News/PublishedNewsList',
    // url: '/platform-management-server/News/PublishedNewsList',
    method: 'post',
    data
  })
}

//新闻详情
export async function newsDetails(data) {
  return await request({
    url: '/business-server/News/NewsDetails',
    // url: '/platform-management-server/News/NewsDetails',
    method: 'post',
    data
  })
}

//轮播图
export async function slideshow(data) {
  return await request({
    url: '/business-server/HomePage/slideshow',
    method: 'post',
    data
  })
}

//查询艺术家信息
export async function findBrandInfoList(data) {
  return await request({
    url: '/business-server/HomePage/findBrandInfoList',
    method: 'post',
    data
  })
}

import testRequest from '@/utils/testRequest'//20050

//拍卖
import userRequest from '@/utils/userRequest'//20050

//拍卖列表
export async function auctionCommodityList(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/AuctionCommodityList',
    method: 'post',
    data
  })
}

//拍卖订单列表
export async function auctionDealList(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/AuctionDealList',
    method: 'post',
    data
  })
}

//拍卖商品再拍卖
export async function successfullyForAuction(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/SuccessfullyForAuction',
    method: 'post',
    data
  })
}

//取消商品再拍卖
export async function cancelAuction(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/CancelAuction',
    method: 'post',
    data
  })
}

//用户是否报名
export async function registered(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/Registered',
    method: 'post',
    data
  })
}

//围观记录
export async function observationRecord(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/observationRecord',
    method: 'post',
    data
  })
}

//报名
export async function application(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/Application',
    method: 'post',
    data
  })
}

//出价
export async function auction(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/Auction',
    method: 'post',
    data
  })
}

//出价延时
export async function updateAuctionEndTime(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/UpdateAuctionEndTime',
    method: 'post',
    data
  })
}

//出价记录
export async function auctionRecord(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/AuctionRecord',
    method: 'post',
    data
  })
}

//出价记录
export async function auctionRecordList(data) {
  return await request({
    url: '/business-server/CommodityMgt/AuctionRecordList',
    method: 'post',
    data
  })
}



//保证金支付
export async function marginPay(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/marginPay',
    method: 'post',
    data
  })
}


//保证金支付查询
export async function marginPayQuery(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/marginPayQuery',
    method: 'post',
    data
  })
}

//拍卖支付
export async function orderPay(data) {
  return await userRequest({
    url: '/consumer-shopping-server/AuctionPayment/orderPay',
    method: 'post',
    data
  })
}

//保证金详情
export async function queryMarginInfo(data) {
  return await userRequest({
    url: '/consumer-shopping-server/Auction/queryMarginInfo',
    method: 'post',
    data
  })
}

//短信通知
export async function pushSellerMessageNotice(data) {
  return await userRequest({
    url: '/consumer-shopping-server/CusOrder/PushSellerMessageNotice',
    method: 'post',
    data
  })
}


//单号识别
export async function numberIdentification(data) {
  return await userRequest({
    url: '/consumer-shopping-server/CusOrder/numberIdentification',
    method: 'post',
    data
  })
}

//修改物流信息
export async function updateWaybillInfo(data) {
  return await userRequest({
    url: '/consumer-shopping-server/CusOrder/UpdateWaybillInfo',
    method: 'post',
    data
  })
}


export async function queryInvoice(data) {
	return await userRequest({
		url: '/consumer-shopping-server/Order/queryInvoice',
		method: 'post',
		data
	})
}


//保单
export async function policyInfoDetails(data) {
	return await request({
		url: '/business-server/CommodityMgt/PolicyInfoDetails',
		method: 'post',
		data
	})
}


// 商品码是否售出
export async function commodityCodeUsed(data) {
  return await userRequest({
    url: '/consumer-shopping-server/RegCode/CommodityCodeUsed',
    method: 'post',
    data
  })
}