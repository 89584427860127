import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueLazyLoad from 'vue-lazyload'
import echarts from 'echarts'

import * as API from './api'
import './style.css'
import 'element-ui/lib/theme-chalk/index.css';
import '@/icons'



Vue.prototype.$API = API
Vue.prototype.$echarts = echarts
Vue.use(ElementUI);

Vue.use(VueLazyLoad, {
  preLoad: 1.2,
  error: require('./assets/images/error.png'),
  loading: require('./assets/images/loading.gif'),
  attempt: 2,
})


Vue.config.productionTip = false

const vue =new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vue
