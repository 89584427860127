import axios from 'axios'
import _this from '@/main.js'
import { getToken } from "@/utils/auth"


// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_CLASS_API, // url = base url + request url 
    // 20030 网关

    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000, // request timeout
})
service.interceptors.request.use(config => {

    config.headers['Region'] = "WH"
    // config.headers['Region'] = "QG"
    // config.headers['Region'] = "CS"

    if (config.url === "/platform-management-server/CusOrder/numberIdentification") {
        const token = getToken();
        token && (config.headers.common['Authorization'] = `Bearer ${token}`)
    }
    
    console.log(config);
    return config
},
    err => {
        console.log(err)
        return Promise.reject(err)
    });

service.interceptors.response.use(response => {
    if (response.data.code !== "SUCCESS") {
        _this.$message.error(response.data.msg);
        return undefined
    } else {
        return response; // 有且必须有一个response对象被返回
    }
}, error => {
    console.log(error);
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default service
