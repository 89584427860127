import Vue from 'vue'
import Vuex from 'vuex'
import { getUser, findUserById, queryApplications, verification, selectSettleAcctList, queryAccountInfo } from "@/api/user"
import { getShoppingCartSum } from "@/api/order"
import { setToken, removeToken } from "@/utils/auth"

import _this from '@/main.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    type: 1,

    loginShow: false,
    isLogin: false,
    userInfo: null,
    quantity: 0,
    isElectronicAcct: false,
    isRealName: false
  },
  mutations: {
    loginShowSwitch(state, payload) {
      state.loginShow = !state.loginShow
      if (payload) payload.resetFields()
    },
    setUserInfo(state, payload) {
      // if(!payload) return
      state.isLogin = true
      state.userInfo = payload
    },
    setQuantity(state, payload) {
      payload === null ? state.quantity = 0 : state.quantity = payload.sum
    },
    LoginOut(state, payload) {
      removeToken()
      state.isLogin = false
      state.userInfo = null
      state.isElectronicAcct = false
      state.isRealName = false
      state.quantity = 0
    },
    setIsElectronicAcct(state, payload) {
      state.isElectronicAcct = payload
    },
    setIsRealName(state, payload) {
      state.isRealName = payload
    },

  },
  actions: {
    async getUser(context) {
      await getUser({ object: {} }).then(res => {
        if (!res) throw Error(res.data.msg)
        let userInfo = res.data.data.userInfo
        context.commit('setUserInfo', userInfo);
        context.dispatch("getUserById")
      }).catch(e => {
        removeToken()
        _this.$router.push('/')
        return
      })
    },
    async getUserById(context) {
      await findUserById({
        "object": {
          "id": context.state.userInfo.id
        }
      }).then(res => {
        let userInfo = res.data.data.userInfo
        userInfo.userEntity.telNum2 = userInfo.userEntity.telNum.substr(0, 3) +
          "****" +
          userInfo.userEntity.telNum.substr(7);

        context.commit('setUserInfo', userInfo);
        context.dispatch("verification")
        context.dispatch("getQuantity")
        context.dispatch("queryApplications")
      })
    },
    async getQuantity(context) {
      const data = await getShoppingCartSum({
        object: {
          userId: context.state.userInfo.id
        }
      })
      context.commit('setQuantity', data.data.data)
    },
    async queryApplications(context) {

      try {
        if (context.state.type) {
          await queryAccountInfo({
            "object": context.state.userInfo.id
          })
            .then((res) => {
              console.log(res);
              if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
              if (res.data.data && res.data.data.userStatus === '0')
                context.commit('setIsElectronicAcct', true)
            })
        } else {
          await queryApplications({
            object: {
              serviceType: 1,
              userId: context.state.userInfo.id
              // userId: "E01352BB31944041AA311A0F56E1CEFD",
            },
          })
            .then((res) => {
              if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
              if (res.data.data.status) context.commit('setIsElectronicAcct', true)
            })
        }
      } catch (e) {
        _this.$message.error(e.message);
      }
    },
    async verification(context) {
      await verification({
        object: {
          idNumber: "1",
          name: "1",
          telNum: context.state.userInfo.userEntity.telNum,
        },
      })
        .then((res) => {
          if (res.data.data === "2")
            context.commit('setIsRealName', true)
        });
    },
    async selectSettleAcctList(context) {
      await selectSettleAcctList({
        object: {
          bankAcctNo: "",
          serviceType: "1",
          settleAcctId: "",
          userId: context.state.userInfo.id
        }
      }).then(res => {
        console.log(res);
      })

    }
  },
  // modules: {
  // }
})
